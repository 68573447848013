import { useState, useRef, useEffect } from 'react';

const Signature = () => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [hasSignature, setHasSignature] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [lastPos, setLastPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      const container = canvas.parentElement;
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;

      ctx.strokeStyle = '#000';
      ctx.lineWidth = 2;
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  const getCoordinates = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    let x, y;

    if (e.type.includes('touch')) {
      x = e.touches[0].clientX - rect.left;
      y = e.touches[0].clientY - rect.top;
    } else {
      x = e.clientX - rect.left;
      y = e.clientY - rect.top;
    }

    x = x * (canvas.width / rect.width);
    y = y * (canvas.height / rect.height);

    return [x, y];
  };

  const startDrawing = (e) => {
    setIsDrawing(true);
    setHasSignature(true);
    const [x, y] = getCoordinates(e);
    setLastPos({ x, y });
  };

  const draw = (e) => {
    if (!isDrawing) return;
    e.preventDefault();

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const [currentX, currentY] = getCoordinates(e);

    ctx.beginPath();
    ctx.moveTo(lastPos.x, lastPos.y);
    ctx.lineTo(currentX, currentY);
    ctx.stroke();

    setLastPos({ x: currentX, y: currentY });
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setHasSignature(false);
  };

  const createCertificateCanvas = (signature) => {
    const certificateCanvas = document.createElement('canvas');
    certificateCanvas.width = 800;
    certificateCanvas.height = 600;
    const certCtx = certificateCanvas.getContext('2d');

    // Set background
    certCtx.fillStyle = '#f5f5f5';
    certCtx.fillRect(0, 0, 800, 600);

    // Add decorative border
    certCtx.strokeStyle = '#8b4513';
    certCtx.lineWidth = 8;
    certCtx.strokeRect(20, 20, 760, 560);

    // Add inner border
    certCtx.strokeStyle = '#d4af37';
    certCtx.lineWidth = 2;
    certCtx.strokeRect(40, 40, 720, 520);

    // Add corner decorations
    const drawCorner = (x, y, rotate) => {
      certCtx.save();
      certCtx.translate(x, y);
      certCtx.rotate((rotate * Math.PI) / 180);

      certCtx.beginPath();
      certCtx.moveTo(0, 0);
      certCtx.lineTo(30, 0);
      certCtx.lineTo(0, 30);
      certCtx.closePath();
      certCtx.fillStyle = '#d4af37';
      certCtx.fill();

      certCtx.restore();
    };

    // Draw corners
    drawCorner(40, 40, 0);
    drawCorner(760, 40, 90);
    drawCorner(760, 560, 180);
    drawCorner(40, 560, 270);

    // Add title
    certCtx.font = 'bold 36px Arial';
    certCtx.fillStyle = '#333';
    certCtx.textAlign = 'center';
    certCtx.fillText('Digital Signature', 400, 100);

    // Add date
    const date = new Date().toLocaleDateString();
    certCtx.font = '16px Arial';
    certCtx.fillText(`Date: ${date}`, 400, 500);

    // Add signature area
    certCtx.font = '16px Arial';
    certCtx.fillText('Signature:', 400, 300);

    // Add the signature
    const signatureWidth = 300;
    const signatureHeight = 100;
    const signatureX = 400 - signatureWidth / 2;
    const signatureY = 320;

    // Draw signature background
    certCtx.fillStyle = '#fff';
    certCtx.fillRect(signatureX, signatureY, signatureWidth, signatureHeight);

    // Scale and draw the signature
    const signatureImage = new Image();
    signatureImage.src = signature;
    signatureImage.onload = () => {
      certCtx.drawImage(
        signatureImage,
        signatureX,
        signatureY,
        signatureWidth,
        signatureHeight
      );

      // Download the certificate
      const link = document.createElement('a');
      link.download = 'signature-certificate.png';
      link.href = certificateCanvas.toDataURL('image/png');
      link.click();
    };
  };

  const saveSignature = () => {
    if (!hasSignature) {
      setMessage({
        text: 'Please draw a signature first',
        type: 'error',
      });
      setTimeout(() => setMessage({ text: '', type: '' }), 3000);
      return;
    }

    const signatureImage = canvasRef.current.toDataURL('image/png');
    createCertificateCanvas(signatureImage);

    setMessage({
      text: 'Signature saved successfully!',
      type: 'success',
    });
    setTimeout(() => setMessage({ text: '', type: '' }), 3000);
  };

  return (
    <div className="w-full h-screen flex flex-col p-5 relative">
      <div className="flex-1 w-full relative">
        <canvas
          ref={canvasRef}
          className="absolute inset-0 border border-gray-300 rounded cursor-crosshair bg-white"
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseOut={stopDrawing}
          onTouchStart={startDrawing}
          onTouchMove={draw}
          onTouchEnd={stopDrawing}
        />
      </div>
      <div className="mt-5 text-center">
        <button
          onClick={clearCanvas}
          className="px-3 py-2.5 text-base bg-red-500 text-white rounded mr-2 hover:opacity-90"
        >
          Clear
        </button>
        <button
          onClick={saveSignature}
          className="px-3 py-2.5 text-base bg-[#142D52] text-white rounded hover:opacity-90"
        >
          Save Signature
        </button>
      </div>
      {message.text && (
        <div
          className={`fixed top-5 right-5 p-3 rounded ${
            message.type === 'success'
              ? 'bg-green-100 text-green-700 border border-green-200'
              : 'bg-red-100 text-red-700 border border-red-200'
          }`}
        >
          {message.text}
        </div>
      )}
    </div>
  );
};

export default Signature;
