import { useRef, useState } from 'react';
import { getApi, postApi } from '../utils/api';
import { rangeBookingApi, rangePoliciesApi } from '../utils/endpoint';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { setHours, setMinutes, format } from 'date-fns';
import { ThreeDots } from 'react-loader-spinner';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Signature from './Signature';
import MainLayout from '../Layout/MainLayout';

const RangeBookingForm = () => {
  const { personalDetail } = useSelector((state) => state.signup);
  const { state } = useLocation();
  const selectedRange = state && state.range;
  console.log(selectedRange);
  const today = new Date().toISOString().split('T')[0].toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [noOfParticipants, setNoOfParticipants] = useState(1);
  const [skillLevel, setSkillLevel] = useState('Novice/Never Ever');
  const [firearm, setFirearm] = useState('Handgun');
  const [rangePolicy, setRangePolicy] = useState(false);
  const [notes, setNotes] = useState('');
  const [isBookLoading, setIsBookLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBookingDone, setIsBookingDone] = useState({
    msgText: '',
    isSuccess: false,
  });
  const access_token = Cookies.get('userToken');
  const [availableDays, setAvailableDays] = useState([]);
  const [minTime, setMinTime] = useState();
  const [maxTime, setMaxTime] = useState();
  // let timeoutIdDemo;
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNo: '',
    gender: '',
  });
  const [rangePolicyList, setRangePolicyList] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const handleBook = (e) => {
    e.preventDefault();
    setIsBookingDone({
      msgText: '',
      isSuccess: false,
    });

    const participantArray = [
      {
        fullName: personalDetail?.name,
        email: personalDetail?.email,
        phoneNo: personalDetail?.telephone,
        gender: personalDetail?.gender,
      },
    ];

    if (noOfParticipants === '2') {
      participantArray.push(formData);
    }

    if (
      formData.email === personalDetail?.email ||
      formData.phoneNo === personalDetail?.telephone
    ) {
      let errorMessage = 'Participant details cannot be same as yours.';

      if (
        formData.email === personalDetail?.email &&
        formData.phoneNo !== personalDetail?.telephone
      ) {
        errorMessage =
          'The email you provided is already associated with an account. ';
      }

      if (
        formData.phoneNo === personalDetail?.telephone &&
        formData.email !== personalDetail?.email
      ) {
        errorMessage =
          'The phone number you provided is already associated with an account. ';
      }

      setIsBookingDone({
        msgText: errorMessage,
        isSuccess: false,
      });
      setIsBookLoading(false);
      return;
    }

    if (rangePolicy) {
      setIsBookLoading(true);
      const formdata = new FormData();
      formdata.append('range_id', selectedRange?.id);
      formdata.append('date', format(new Date(date), 'dd-MM-yyyy'));
      formdata.append('time', format(new Date(time), 'h:mm aa'));
      formdata.append('no_of_participants', noOfParticipants);
      formdata.append('participants', JSON.stringify(participantArray));
      formdata.append('firearm', firearm);
      formdata.append('skill_level', skillLevel);
      formdata.append('notes', notes);
      postApi(rangeBookingApi, formdata, access_token)
        .then((res) => {
          if (res?.data?.success) {
            setIsBookingDone({
              msgText:
                'Your reservation request is submitted successfully. Our range rep will contact you soon. Thank you!',
              isSuccess: true,
            });

            // timeoutIdDemo = setTimeout(() => {
            //   setIsBookingDone({
            //     msgText: '',
            //     isSuccess: false,
            //   });
            //   const modalClose =
            //     document.getElementsByClassName('cancel-booking')[0];
            //   modalClose.click();
            // }, 5000);
            // setTimeoutId(timeoutIdDemo);
          }
          setIsBookLoading(false);
          handleClose();
        })
        .catch((err) => {
          setIsBookingDone({
            msgText: err?.response?.data.message,
            isSuccess: false,
          });
          setIsBookLoading(false);
        });
    } else {
      setIsBookingDone({
        msgText: 'Please read and agree to Range Policies.',
        isSuccess: false,
      });
      setIsBookLoading(false);
    }
  };

  const handleClose = () => {
    setDate('');
    // clearTimeout(timeoutId);
    setTime('');
    setNoOfParticipants(1);
    setNotes('');
    // setTimeoutId();
    setIsBookingDone({
      msgText: '',
      isSuccess: false,
    });
    setFirearm('Pistol Red-Dot');
    setSkillLevel('Novice/Never Ever');
    setRangePolicy(false);
    setFormData({
      fullName: '',
      email: '',
      phoneNo: '',
      gender: '',
    });
    navigate('/find-a-range');
  };

  useEffect(() => {
    setAvailableDays([]);
    if (Object.keys(selectedRange).length > 0) {
      // Filter out closed days
      const closeDays = selectedRange?.range_hours.filter(
        (day) => day.closed === 1
      );
      if (closeDays.length > 0) {
        let excludelistmap = closeDays.map((item) => {
          let excludelist = generateExcludedDatesForDayOfWeek(Number(item.day));
          return excludelist;
        });
        const allExcludedDates = excludelistmap.flat();
        setAvailableDays(allExcludedDates);
      }
    }
  }, [selectedRange]);

  useEffect(() => {
    if (selectedRange?.id) {
      fetchRangePolicy();
    }
  }, [selectedRange?.id]);

  const fetchRangePolicy = () => {
    getApi(
      rangePoliciesApi + `?range_id=${selectedRange?.id}&type=policy-waiver`,
      access_token
    )
      .then((res) => {
        if (res?.data?.success) {
          setRangePolicyList(res?.data.data);
          console.log(res?.data.data, isLoading);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateExcludedDatesForDayOfWeek = (dayIndex) => {
    const excludedDates = [];
    // Loop through each month of the year
    for (let month = 0; month < 12; month++) {
      // Calculate the start and end dates of the current month
      const startDate = new Date(new Date().getFullYear(), month, 1);
      const endDate = new Date(new Date().getFullYear(), month + 1, 0);
      for (
        let currentDate = new Date(startDate);
        currentDate <= endDate;
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        const adjustedDayIndex = (currentDate.getDay() + 6) % 7;
        // Check if the current day matches the specified dayIndex (0 for Monday, 1 for Tuesday, ..., 6 for Sunday)
        if (adjustedDayIndex === dayIndex) {
          excludedDates.push(new Date(currentDate));
        }
      }
    }

    return excludedDates;
  };

  const handleDate = (date) => {
    const currentDate = new Date(
      new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York',
      })
    );
    const selectedDay = (date.getDay() + 6) % 7;
    const availableHoursForDay = selectedRange?.range_hours.find(
      (range) => Number(range.day) === selectedDay
    );

    if (availableHoursForDay) {
      let minDateTime = new Date();
      let maxDateTime = new Date();
      const startTimeArray = availableHoursForDay?.start_time
        .split(':')
        .map((str) => parseInt(str, 10));
      const currentHours = currentDate.getHours();
      const currentMinutes = currentDate.getMinutes();
      if (
        date.getDate() === currentDate.getDate() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear()
      ) {
        // If the selected date is the current day, set minimum time to the current time
        if (
          currentHours > startTimeArray[0] ||
          (currentHours === startTimeArray[0] &&
            currentMinutes >= startTimeArray[1])
        ) {
          minDateTime = currentDate;
        } else {
          minDateTime = new Date(
            setHours(
              setMinutes(
                new Date(),
                availableHoursForDay?.start_time.split(':')[1]
              ),
              parseInt(availableHoursForDay?.start_time.split(':')[0], 10)
            )
          );
        }
      } else {
        minDateTime = new Date(
          setHours(
            setMinutes(
              new Date(),
              availableHoursForDay?.start_time.split(':')[1]
            ),
            parseInt(availableHoursForDay?.start_time.split(':')[0], 10)
          )
        );
      }

      // Set maximum time
      maxDateTime = new Date(
        setHours(
          setMinutes(new Date(), availableHoursForDay?.end_time.split(':')[1]),
          parseInt(availableHoursForDay?.end_time.split(':')[0], 10)
        )
      );

      setMinTime(minDateTime);
      setMaxTime(maxDateTime);
    }
    setDate(date);
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      setIsBookLoading(true);
      // Loop through each form element and remove error className
      Array.from(form.elements).forEach((element) => {
        element.classList.remove('error-input-border-red');
      });
      await handleBook(e);
    } else {
      // Loop through each form element and add error className if invalid
      Array.from(form.elements).forEach((element) => {
        if (!element.checkValidity()) {
          element.classList.add('error-input-border-red');
        } else {
          element.classList.remove('error-input-border-red');
        }
      });
      form.reportValidity();
    }
  };

  return (
    <>
      <MainLayout>
        <div className="account-login pt-5">
          <div className="modal-header firing-pin">
            <h2 className="modal-title  firing-heading" id="exampleModalLabel">
              {selectedRange?.name}
            </h2>
            {/* <button
              type="button"
              className="btn-close cancel-booking"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button> */}
          </div>
          <form ref={formRef}>
            <div className="modal-body ">
              <div className="row grid-lay-out pb-0 m-b">
                <div className=" grid-tabs-list">
                  <div className="floating-group select-floating w-100 mb-f">
                    <DatePicker
                      selected={date}
                      required
                      onChange={(date) => handleDate(date)}
                      className="form-control"
                      placeholderText="dd-mm-yyyy"
                      excludeDates={availableDays}
                      dateFormat="dd-MM-yyyy"
                      disabled={isBookingDone?.isSuccess}
                      minDate={today}
                    />
                    <label htmlFor="name" className="form-label label-text">
                      Choose Date
                    </label>
                    {/* <div className="icon-form">
                <i className="fa fa-calendar-check-o"></i>
              </div> */}
                  </div>
                  <div className="floating-group select-floating w-100 mb-f">
                    <DatePicker
                      className="form-control"
                      required
                      selected={time}
                      onChange={(date) => setTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      placeholderText="hh:mm"
                      disabled={isBookingDone?.isSuccess || !date}
                      timeIntervals={60}
                      minTime={minTime}
                      maxTime={maxTime}
                    />
                    <label htmlFor="time" className="form-label label-text">
                      Choose Time
                    </label>
                    {/* <div className="icon-form">
                <i className="fa fa-clock-o"></i>
              </div> */}
                  </div>
                  <div className="floating-group select-floating w-100 mb-f">
                    <select
                      id="inputState"
                      className="form-select st"
                      onChange={(e) => setSkillLevel(e.target.value)}
                      value={skillLevel}
                      required
                      disabled={isBookingDone?.isSuccess}
                    >
                      <option value="Novice/Never Ever">
                        Novice/Never Ever
                      </option>
                      <option value="Beginner/In-training">
                        Beginner/In-training
                      </option>
                      <option value="Proficient/Intermediate">
                        Proficient/Intermediate
                      </option>
                      <option value="Advanced/Specialist">
                        Advanced/Specialist
                      </option>
                      <option value="Professional">Professional </option>
                    </select>
                    <label htmlFor="time" className="form-label label-text ">
                      Skill Level
                    </label>
                  </div>
                  <div className="floating-group select-floating w-100 mb-f">
                    <select
                      id="inputState"
                      className="form-select st"
                      onChange={(e) => setFirearm(e.target.value)}
                      value={firearm}
                      required
                      disabled={isBookingDone?.isSuccess}
                    >
                      <option value="Handgun">Handgun</option>
                      <option value="Rifle">Rifle </option>
                      <option value="Shotgun">Shotgun </option>
                    </select>
                    <label htmlFor="time" className="form-label label-text ">
                      Firearm
                    </label>
                  </div>
                  <div className="floating-group select-floating w-100 mb-f">
                    <select
                      id="inputState"
                      className="form-select st"
                      onChange={(e) => {
                        setNoOfParticipants(e.target.value);
                        setFormData({
                          fullName: '',
                          email: '',
                          phoneNo: '',
                          gender: '',
                        });
                      }}
                      value={noOfParticipants}
                      required
                      disabled={isBookingDone?.isSuccess}
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                    </select>
                    <label htmlFor="time" className="form-label label-text ">
                      No. of Participants
                    </label>
                  </div>
                  <div className="d-flex pb-0 m-b w-full-row">
                    <div className="floating-group select-floating w-100 mb-f">
                      <textarea
                        className="form-control input-outline"
                        onChange={(e) => setNotes(e.target.value)}
                        value={notes}
                        disabled={isBookingDone?.isSuccess}
                      ></textarea>
                      <label htmlFor="time" className="form-label label-text">
                        Note
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items-center w-full-row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) => setRangePolicy(e.target.checked)}
                        id="gridCheck"
                        checked={rangePolicy}
                        disabled={isBookingDone?.isSuccess}
                      />
                      <label
                        className="form-check-label mt-1"
                        htmlFor="gridCheck"
                      >
                        I agree to the{' '}
                        <Link target="_blank" to="/range-policy">
                          Range Policies{' '}
                        </Link>
                        {rangePolicyList?.length > 0 && (
                          <>
                            and check{' '}
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              Range Waiver
                            </Link>{' '}
                            here
                          </>
                        )}
                        .
                      </label>
                    </div>
                  </div>
                  <div className="modal-footer border-0 w-full-row pe-0">
                    {!isBookingDone?.isSuccess && (
                      <button
                        type="button"
                        className={
                          'book-now-m book-now me-0' +
                          (isBookLoading ? ' disabled-btn' : '')
                        }
                        disabled={isBookLoading}
                        onClick={handleManualSubmit}
                      >
                        {isBookLoading ? (
                          <ThreeDots
                            height="21"
                            width="90"
                            radius="9"
                            color="#fff"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="800px"
                              height="800px"
                              viewBox="0 0 24 24"
                              id="Layer_1"
                              data-name="Layer 1"
                              fill="#fff"
                              className="cc"
                            >
                              <line
                                className="cls-1"
                                x1="12"
                                y1="0.5"
                                x2="12"
                                y2="5.29"
                              />
                              <line
                                className="cls-1"
                                x1="12"
                                y1="18.71"
                                x2="12"
                                y2="23.5"
                              />
                              <line
                                className="cls-1"
                                x1="23.5"
                                y1="12"
                                x2="18.71"
                                y2="12"
                              />
                              <line
                                className="cls-1"
                                x1="5.29"
                                y1="12"
                                x2="0.5"
                                y2="12"
                              />
                              <line
                                className="cls-1"
                                x1="20.13"
                                y1="3.87"
                                x2="16.74"
                                y2="7.26"
                              />
                              <line
                                className="cls-1"
                                x1="7.26"
                                y1="16.74"
                                x2="3.87"
                                y2="20.13"
                              />
                              <line
                                className="cls-1"
                                x1="20.13"
                                y1="20.13"
                                x2="16.74"
                                y2="16.74"
                              />
                              <line
                                className="cls-1"
                                x1="7.26"
                                y1="7.26"
                                x2="3.87"
                                y2="3.87"
                              />
                            </svg>{' '}
                            Book Now
                          </>
                        )}
                      </button>
                    )}
                  </div>
                </div>

                <div className="">
                  <div className="grid-list-new-ui">
                    <div className="card">
                      <button type="button" className="card-top-list">
                        Member
                      </button>
                      <div className="list-user-create">
                        <ul>
                          <li>
                            Name <span>Ajay</span>
                          </li>
                          <li>
                            Email <span>ajay@yopmail.com</span>
                          </li>
                          <li>
                            Mobile <span>+9788888888</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="#"
                    className="link-text d-block mb-3"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    Add New Participant
                  </Link>
                  <div className="grid-list-new-ui">
                    <div className="card">
                      <button type="button" className="card-top-list">
                        Participant
                      </button>
                      <div className="list-user-create">
                        <ul>
                          <li>
                            Name <span>Ajay</span>
                          </li>
                          <li>
                            Email <span>ajay@yopmail.com</span>
                          </li>
                          <li>
                            Mobile <span>+9788888888</span>
                          </li>
                        </ul>
                        <div className="only-btn d-flex default-bottom gap-3 justify-content-center ml-auto mr-auto mt-auto">
                          <Link
                            className="button-black size-button size-border"
                            to="#"
                          >
                            Edit
                          </Link>
                          <Link
                            className="button-black size-button size-border"
                            to="#"
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid-list-new-ui d-none">
                    <div className="card">
                      <button type="button" className="card-top-list">
                        Participant
                      </button>
                      <div className="grid-list-u d-none">
                        <div className="floating-group select-floating w-100 mb-f">
                          <input
                            id="inputState"
                            value={personalDetail?.name}
                            name="fullName"
                            type="text"
                            className={'form-control input-outline'}
                            required
                          />
                          <label
                            htmlFor="time"
                            className="form-label label-text "
                          >
                            Full Name
                          </label>
                        </div>
                        <div className="floating-group select-floating w-100 mb-f">
                          <input
                            id="inputState"
                            value={personalDetail.email}
                            name="email"
                            type="email"
                            className={'form-control input-outline'}
                            required
                          />
                          <label
                            htmlFor="time"
                            className="form-label label-text "
                          >
                            Email
                          </label>
                        </div>
                        <div className="floating-group select-floating w-100 mb-f">
                          <input
                            type="tel"
                            maxLength="10"
                            minLength="10"
                            onKeyPress={(e) => {
                              const regex = /^[0-9\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputState"
                            className={'form-control input-outline'}
                            value={personalDetail.telephone}
                            name="phoneNo"
                          />
                          <label
                            htmlFor="time"
                            className="form-label label-text "
                          >
                            Mobile
                          </label>
                        </div>
                        <div className="floating-group select-floating w-100 mb-f">
                          <select
                            id="inputState"
                            className="form-select st"
                            value={personalDetail.gender}
                            name="gender"
                            required
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non_Binary">Non Binary</option>
                          </select>
                          <label
                            htmlFor="time"
                            className="form-label label-text "
                          >
                            Gender
                          </label>
                        </div>
                        <div className="only-btn d-flex default-bottom gap-3 justify-content-center ml-auto mr-auto mt-auto">
                          <Link
                            className="button-black size-button size-border"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            to="/profile-preferences"
                          >
                            Edit
                          </Link>
                        </div>
                      </div>
                      <div className="grid-list-u">
                        <div className="floating-group select-floating w-100 mb-f">
                          <input
                            id="inputState"
                            value={personalDetail?.name}
                            name="fullName"
                            type="text"
                            className={'form-control input-outline'}
                            required
                            disabled
                          />
                          <label
                            htmlFor="time"
                            className="form-label label-text "
                          >
                            Full Name
                          </label>
                        </div>
                        <div className="floating-group select-floating w-100 mb-f">
                          <input
                            id="inputState"
                            value={personalDetail.email}
                            name="email"
                            type="email"
                            className={'form-control input-outline'}
                            required
                            disabled
                          />
                          <label
                            htmlFor="time"
                            className="form-label label-text "
                          >
                            Email
                          </label>
                        </div>
                        <div className="floating-group select-floating w-100 mb-f">
                          <input
                            type="tel"
                            maxLength="10"
                            minLength="10"
                            onKeyPress={(e) => {
                              const regex = /^[0-9\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            id="inputState"
                            className={'form-control input-outline'}
                            value={personalDetail.telephone}
                            name="phoneNo"
                            disabled
                          />
                          <label
                            htmlFor="time"
                            className="form-label label-text "
                          >
                            Mobile
                          </label>
                        </div>
                        <div className="floating-group select-floating w-100 mb-f">
                          <select
                            id="inputState"
                            className="form-select st"
                            value={personalDetail.gender}
                            name="gender"
                            required
                            disabled
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non_Binary">Non Binary</option>
                          </select>
                          <label
                            htmlFor="time"
                            className="form-label label-text "
                          >
                            Gender
                          </label>
                        </div>
                        <div className="only-btn d-flex default-bottom gap-3 justify-content-center ml-auto mr-auto mt-auto">
                          <Link
                            className="button-black size-button size-border"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            to="/profile-preferences"
                          >
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-md-3 pb-0 m-b d-none">
                <div className="floating-group select-floating w-100 mb-f">
                  <input
                    id="inputState"
                    value={personalDetail?.name}
                    name="fullName"
                    disabled
                    type="text"
                    className={'form-control input-outline'}
                    required
                  />
                  <label htmlFor="time" className="form-label label-text ">
                    Full Name
                  </label>
                </div>
                <div className="floating-group select-floating w-100 mb-f">
                  <input
                    id="inputState"
                    value={personalDetail.email}
                    name="email"
                    disabled
                    type="email"
                    className={'form-control input-outline'}
                    required
                  />
                  <label htmlFor="time" className="form-label label-text ">
                    Email
                  </label>
                </div>
                <div className="floating-group select-floating w-100 mb-f">
                  <input
                    type="tel"
                    maxLength="10"
                    minLength="10"
                    onKeyPress={(e) => {
                      const regex = /^[0-9\b]+$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    id="inputState"
                    disabled
                    className={'form-control input-outline'}
                    required
                    value={personalDetail.telephone}
                    name="phoneNo"
                  />
                  <label htmlFor="time" className="form-label label-text ">
                    Mobile
                  </label>
                </div>
                <div className="floating-group select-floating w-100 mb-f">
                  <select
                    id="inputState"
                    className="form-select st"
                    value={personalDetail.gender}
                    name="gender"
                    required
                    disabled
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Non_Binary">Non Binary</option>
                  </select>
                  <label htmlFor="time" className="form-label label-text ">
                    Gender
                  </label>
                </div>
              </div>

              {noOfParticipants === '2' && (
                <div className="d-flex gap-md-3 pb-0 m-b">
                  <div className="floating-group select-floating w-100 mb-f">
                    <input
                      id="inputState"
                      value={formData.fullName}
                      onChange={handleInputChange}
                      name="fullName"
                      disabled={isBookingDone?.isSuccess}
                      type="text"
                      className={'form-control input-outline'}
                      required
                    />
                    <label htmlFor="time" className="form-label label-text ">
                      Full Name
                    </label>
                  </div>

                  <div className="floating-group select-floating w-100 mb-f">
                    <input
                      id="inputState"
                      value={formData.email}
                      onChange={handleInputChange}
                      name="email"
                      disabled={isBookingDone?.isSuccess}
                      type="email"
                      className={'form-control input-outline'}
                      required
                    />
                    <label htmlFor="time" className="form-label label-text ">
                      Email
                    </label>
                  </div>
                  <div className="floating-group select-floating w-100 mb-f">
                    <input
                      type="tel"
                      maxLength="10"
                      minLength="10"
                      onKeyPress={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      id="inputState"
                      disabled={isBookingDone?.isSuccess}
                      className={'form-control input-outline'}
                      required
                      value={formData.phoneNo}
                      onChange={handleInputChange}
                      name="phoneNo"
                    />
                    <label htmlFor="time" className="form-label label-text ">
                      Mobile
                    </label>
                  </div>
                  <div className="floating-group select-floating w-100 mb-f">
                    <select
                      id="inputState"
                      className="form-select st"
                      value={formData.gender}
                      onChange={handleInputChange}
                      name="gender"
                      required
                      disabled={isBookingDone?.isSuccess}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Non_Binary">Non Binary</option>
                    </select>
                    <label htmlFor="time" className="form-label label-text ">
                      Gender
                    </label>
                  </div>
                </div>
              )}

              {isBookingDone.msgText && (
                <p
                  className={
                    isBookingDone?.isSuccess
                      ? 'text-center text-success mt-3'
                      : 'text-center text-danger  mt-3'
                  }
                >
                  {isBookingDone.msgText}
                </p>
              )}
            </div>
          </form>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title h-m" id="exampleModalLabel">
                  Waiver for {selectedRange?.name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="d-flex gap-md-3 pb-0 m-b">
                  {rangePolicyList?.map((item, index) => (
                    <div key={index}>
                      <div className="flex justify-between items-center">
                        <h6 className="text-lg font-bold text-gray-900">
                          {item?.title}
                        </h6>
                        <div
                          dangerouslySetInnerHTML={{ __html: item?.content }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-flex gap-md-3 pb-0 m-b">
                  <div>
                    <h3 className="page-h text-center">
                      Please select who will be participating...
                    </h3>
                    <div className="d-flex gap-2 justify-content-center">
                      <button type="button" className="theme-btn">
                        Adult
                      </button>
                      <button type="button" className="theme-btn">
                        Minor
                      </button>
                    </div>

                    <div className="agreement text-center mt-3">
                      <h3 className="page-h text-center">
                        This agreement is just for you
                      </h3>
                      <button type="button" className="theme-btn mt-0">
                        Continue
                      </button>
                    </div>
                    <div className="row">
                      <div className="minor">
                        <div className="row">
                          <div className="form-group col-sm-12">
                            <h3 className="h-d">Minor&apos;s Name</h3>
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="firstName" className="l-b">
                              First Name
                            </label>
                            <input
                              className="form-control i-t"
                              type="text"
                              placeholder="First Name"
                            />
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="middleName" className="l-b">
                              Middle Name
                            </label>
                            <input
                              className="form-control i-t"
                              type="text"
                              placeholder="Middle Name"
                            />
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="lastName" className="l-b">
                              Last Name
                            </label>
                            <input
                              className="form-control i-t"
                              type="text"
                              placeholder="Last Name"
                            />
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="number" className="l-b">
                              Phone Number
                            </label>
                            <input
                              className="form-control i-t"
                              type="tell"
                              placeholder="Phone Number"
                            />
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="dob" className="l-b">
                              DOB
                            </label>
                            <input
                              className="form-control i-t"
                              type="date"
                              placeholder="DOB"
                            />
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="dob" className="l-b">
                              Email address
                            </label>
                            <input
                              className="form-control i-t"
                              type="email"
                              placeholder="Email address"
                            />
                          </div>
                          <div className="form-gropu col-md-12 mt-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck-3"
                              />
                              <label
                                className="form-check-label l-b"
                                htmlFor="gridCheck-3"
                              >
                                Check to receive information, news, and
                                discounts by e-mail.
                              </label>
                            </div>
                            <label htmlFor="name" className="l-b ps-4">
                              Your signed waiver will be sent to the email
                              address provided here and is available for
                              download for three days via URL attachment.
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 minor-parent-or-guardina-name">
                        <div className="row">
                          <div className="form-group col-sm-12">
                            <p className="count-parent">
                              Parent(s) or Court-Appointed Legal Guardian(s)
                              must sign for any participating minor (those under
                              18 years of age) and agree that they and the minor
                              are subject to all the terms of this document, as
                              set forth above.
                            </p>
                          </div>
                          <div className="form-group col-sm-12">
                            <h3 className="h-d">Participant&apos;s Name</h3>
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="firstName" className="l-b">
                              First Name
                            </label>
                            <input
                              className="form-control i-t"
                              type="text"
                              placeholder="First Name"
                            />
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="middleName" className="l-b">
                              Middle Name
                            </label>
                            <input
                              className="form-control i-t"
                              type="text"
                              placeholder="Middle Name"
                            />
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="lastName" className="l-b">
                              Last Name
                            </label>
                            <input
                              className="form-control i-t"
                              type="text"
                              placeholder="Last Name"
                            />
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="number" className="l-b">
                              Phone Number
                            </label>
                            <input
                              className="form-control i-t"
                              type="tell"
                              placeholder="Phone Number"
                            />
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="dob" className="l-b">
                              DOB
                            </label>
                            <input
                              className="form-control i-t"
                              type="date"
                              placeholder="DOB"
                            />
                          </div>
                          <div className="form-gropu col-md-4">
                            <label htmlFor="dob" className="l-b">
                              Email address
                            </label>
                            <input
                              className="form-control i-t"
                              type="email"
                              placeholder="Email address"
                            />
                          </div>
                          <div className="form-gropu col-md-12 mt-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck-4"
                              />
                              <label
                                className="form-check-label l-b"
                                htmlFor="gridCheck-4"
                              >
                                Check to receive information, news, and
                                discounts by e-mail.
                              </label>
                            </div>
                            <label htmlFor="name" className="l-b ps-4">
                              Your signed waiver will be sent to the email
                              address provided here and is available for
                              download for three days via URL attachment.
                            </label>
                          </div>
                          <Signature />
                          <div className="d-flex justify-content-center">
                            <button type="button" className="theme-btn">
                              Check to Sign
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-sm-12 mt-4">
                        <h3 className="h-d">Electronic Signature Consent*</h3>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheck-1"
                          />
                          <label
                            className="form-check-label l-b"
                            htmlFor="gridCheck-1"
                          >
                            By checking here, you are consenting to the use of
                            your electronic signature in lieu of an original
                            signature on paper. You have the right to request
                            that you sign a paper copy instead. By checking
                            here, you are waiving that right. After consent, you
                            may, upon written request to us, obtain a paper copy
                            of an electronic record. No fee will be charged for
                            such copy and no special hardware or software is
                            required to view it. Your agreement to use an
                            electronic signature with us for any documents will
                            continue until such time as you notify us in writing
                            that you no longer wish to use an electronic
                            signature. There is no penalty for withdrawing your
                            consent. You should always make sure that we have a
                            current email address in order to contact you
                            regarding any changes, if necessary.
                          </label>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center">
                        <button type="button" className="theme-btn">
                          Agree To This Document
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body d-flex">
                <div className="container">
                  <form action="">
                    <div className="row">
                      <h2 className="profile pro-changes">
                        Add New Participant
                      </h2>
                      <div className="col-lg-6">
                        <div className="floating-group floating-b">
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="fullName"
                          />
                          <label htmlFor="fullName">Full Name*</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-group floating-b">
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="email"
                          />
                          <label htmlFor="floatingSix">Email</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-group floating-b">
                          <input
                            type="tell"
                            className="form-control"
                            required
                            name="number"
                          />
                          <label htmlFor="floatingSeven">Mobile</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-group floating-b select-floating">
                          <select id="gender" className="form-select unite">
                            <option value="Male">Male</option>
                            <option value="Woman">Woman</option>
                            <option value="other">Other</option>
                          </select>
                          <label htmlFor="inputState">Gender</label>
                        </div>
                      </div>
                      <div className="d-flex gap-2 mt-2">
                        <Link className="button-black size-button" to="#">
                          Save
                        </Link>
                        <Link
                          id="cancel-id"
                          className="button-black size-button size-border cancel-add"
                          data-bs-dismiss="modal"
                          to="#"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

RangeBookingForm.propTypes = {
  selectedRange: PropTypes.object,
  setTimeoutId: PropTypes.func,
  timeoutId: PropTypes.number,
};

export default RangeBookingForm;
