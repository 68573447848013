// .env url
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// API endpoints
export const packages = '/v1/plans';
export const signup = '/v1/signup';
export const login = '/v1/login';
export const contact = '/v1/contact';
export const user_subscribe = '/v1/subscribe';
export const checkEmail = '/v1/user/check-email';
export const forgotPassword = '/v1/user/forgot-password';
export const resetPassword = '/v1/user/reset-password';
export const changePassword = '/v1/user/change-password';
export const subscribe = '/v1/user/subscribe';
export const lifetimeSubscribe = '/v1/user/subscribe/lifetime';
export const invoice = '/v1/user/invoices';
export const invoiceView = '/v1/user/invoice/view/';
export const overview = '/v1/user/overview';
export const coverage = '/v1/user/coverage';
export const updateProfile = '/v1/user/update';
export const billingOverview = '/v1/user/billing/overview';
export const membershipCard = '/v1/user/membership-card?id=';
export const addAddress = '/v1/user/address';
export const updateAddress = '/v1/user/address/';
export const paymentMethodApi = '/v1/user/payment-methods';
export const checkCouponApi = '/v1/coupon/';
export const eventsApi = '/v1/events';
export const rangeApi = '/v1/ranges';
export const rangefeatureApi = '/v1/range-features';
export const findRangeApi = '/v1/find-range';
export const rangeBookingApi = '/v1/range-booking';

// Membership Activation
export const getMembershipCardApi = '/v1/getMembershipCard';
export const subscribePrepaidApi = '/v1/user/subscribe/prepaid';

// Cancel subscription
export const cancelSubscriptionApi = '/v1/user/subscription/cancel';

// Policies
export const rangePoliciesApi = '/v1/policies';
